import dynamicSiteConfig from '../../../../src/utils/dynamic/dynamicSiteConfig';

// This is where you dynamically change information for an identical site with a different domain.
// Don't forget to import any images you may need

/**
 *
 * @param {*} config - config object. Use this to alter any config options you need to
 * @param {*} setDocumentTitle - function. Needed to implicitly change the document title
 * @param {*} query - params object.
 */

const customConfig = config => {
  /**
   * updates main config from dynamicSites config
   * @param  {object} config
   */
  dynamicSiteConfig(config);

  const url = document.URL;
  const appWrapper = document.getElementById('main-app');
  if (url.includes('secure.irrrl.com')) {
    if (appWrapper) {
      appWrapper.classList.add('irrrl');
    }
  }
  if (url.includes('secure.irrrl.org')) {
    if (appWrapper) {
      appWrapper.classList.add('irrrl');
    }
  }
  if (url.includes('secure.mortgage.info')) {
    if (appWrapper) {
      appWrapper.classList.add('mortgageInfo');
    }
  }
  if (url.includes('secure.dpasearch.com')) {
    if (appWrapper) {
      appWrapper.classList.add('dpasearch');
    }
  }
  if (url.includes('secure.fha.co')) {
    if (appWrapper) {
      appWrapper.classList.add('fhaCo');
    }
  }
  if (url.includes('secure.nonqualifiedloan.com')) {
    if (appWrapper) {
      appWrapper.classList.add('nonqualified');
    }
  }
  if (url.includes('secure.usda-loan.com')) {
    if (appWrapper) {
      appWrapper.classList.add('usda');
    }
  }
  if (url.includes('secure.usda-loans.com')) {
    if (appWrapper) {
      appWrapper.classList.add('usda');
    }
  }
  if (url.includes('secure.nonprime.com')) {
    if (appWrapper) {
      appWrapper.classList.add('nonprime');
    }
  }
  if (url.includes('secure.mortgagesum.com')) {
    if (appWrapper) {
      appWrapper.classList.add('mortgagesum');
    }
  }
};

export default customConfig;
